/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {whenDocumentReady} from "./utilities/document-extensions";
import {PlatformInitializationAwaiter} from "./core/platform/platform-initialization-awaiter";
import {IPlatform} from "./core/platform/platform.interface";

import './styles/styles.scss';

(async function () {
    const components = await Promise.all([
        PlatformInitializationAwaiter
            .getInstance()
            .whenInitialized(),
        whenDocumentReady()
    ]);

    const platform = components.shift() as IPlatform;
    if (!platform) {
        throw new Error('Platform was not initialized or it is not ready.');
    }

    await platform.start();
})();

