/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

export function whenDocumentReady(): Promise<Document> {
    return new Promise((resolve) => {
        if (document.readyState != 'loading') {
            resolve(document);
        } else if (document.addEventListener) {
            document.addEventListener('DOMContentLoaded', () => {
                resolve(document);
            });
        } else {
            // @ts-ignore
            document.attachEvent('onreadystatechange', () => {
                if (document.readyState == 'complete') {
                    resolve(document);
                }
            });
        }
    });
}
